<template>
  <div class="mt-5">
    <p class="lead">A Empresa</p>

    <p class="text-muted">
      A InovahPlast é uma empresa com 30 anos de experiência sólida no mercado, especializada no segmento de embalagens e frascos plásticos em PEAD (polietileno de alta densidade), tanto novo quanto reciclável. Nossa atuação consiste na produção e distribuição de embalagens de alta qualidade para todo o território nacional. Contamos com uma ampla linha de produtos que atende diversos segmentos, tais como automotivo, cosmético, limpeza e químicos.
    </p>

    <p class="text-muted">
      Nosso principal diferencial é o compromisso em compreender profundamente os produtos de nossos clientes, reconhecendo que cada envase possui uma história única e exerce um impacto significativo no consumidor final. Dessa forma, oferecemos soluções personalizadas, fornecendo as melhores embalagens disponíveis no mercado, garantindo segurança, qualidade e design apropriados para cada produto.
    </p>

    <p class="text-muted">
      Após o processo de produção, todas as nossas embalagens são submetidas a rigorosos testes em nosso laboratório, onde passam por avaliações minuciosas de qualidade, durabilidade e confiabilidade. Essa etapa é fundamental para assegurar que cada produto atenda aos mais altos padrões de excelência e atenda às necessidades específicas de nossos clientes.
    </p>

    <p class="text-muted">
      Nossos profissionais altamente capacitados estão sempre comprometidos em oferecer um serviço de excelência, proporcionando suporte técnico e atendimento personalizado em todas as etapas do processo, desde o desenvolvimento até a entrega do produto final. Além disso, estamos constantemente investindo em tecnologia e inovação para garantir que nossas embalagens estejam alinhadas com as tendências do mercado e as demandas em constante evolução.
    </p>

    <p class="text-muted">
      Acreditamos que a parceria com nossos clientes é essencial para o crescimento mútuo, e nosso objetivo é sermos reconhecidos como a melhor escolha no mercado de embalagens. Com uma trajetória pautada na integridade, responsabilidade e comprometimento com a qualidade, nos orgulhamos de ser uma referência de confiança e excelência em nosso setor.
    </p>

    <p class="text-muted">
      Seja qual for a sua necessidade em embalagens plásticas, a InovahPlast está pronta para oferecer soluções inovadoras e eficientes, elevando o valor de seus produtos no mercado e contribuindo para o sucesso de seus negócios. Conte conosco para levar sua empresa ao próximo nível de excelência e satisfação do cliente.
    </p>

    <p class="lead" style="color: rgb(21, 43, 94)">Nossa Fábrica</p>

    <div class="w-100 h-100 my-4 px-3">
      <CoolLightBox
          :items="items"
          :index="index"
          @close="index = null"
          style="backdrop-filter: blur(7px); background-color: rgba(255, 255, 255, .50)"
      >
      </CoolLightBox>

      <div class="images-wrapper row justify-content-center">
        <div
            class="border border-secondary rounded image zoom col-12 col-sm-4 col-md-3 m-1"
            v-for="(image, imageIndex) in items"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')' }"
            style="height: 200px; width: 200px; background-size: cover; cursor: pointer"></div>
      </div>
    </div>

    <p class="lead">Política da Qualidade</p>

    <p class="text-muted">
      A InovahPlast reafirma o seu compromisso inabalável em atender integralmente aos Requisitos estabelecidos no Sistema de Gestão da Qualidade, visando alcançar a excelência e aprimoramento contínuo de seus processos, baseados nos seguintes princípios fundamentais:
    </p>

    <ul class="list-group">
      <li class="list-group-item">
        <strong>Valorização:</strong> Nossa empresa é norteada pela ética, transparência e respeito nas relações com clientes, colaboradores e fornecedores. Priorizamos a satisfação de nossos parceiros, buscando sempre superar suas expectativas e necessidades.
      </li>

      <li class="list-group-item">
        <strong>Conformidade com Requisitos Aplicáveis:</strong> Comprometemo-nos a atender rigorosamente todas as obrigações legais, normativas e quaisquer outros requisitos aplicáveis relacionados às nossas atividades e produtos. A conformidade é a base para a confiabilidade de nossos produtos e serviços.
      </li>

      <li class="list-group-item">
        <strong>Liderança Engajada:</strong> Promovemos uma liderança inspiradora e comprometida, que envolve e motiva toda a força de trabalho na implementação e desenvolvimento da política de gestão da qualidade. O engajamento de nossos líderes é vital para o sucesso de nossas iniciativas.
      </li>

      <li class="list-group-item">
        <strong>Capacitação e Desenvolvimento:</strong> Reconhecemos que nossos colaboradores são nosso maior ativo. Por isso, investimos no desenvolvimento pessoal e profissional de nossa equipe, proporcionando treinamentos e oportunidades de crescimento, para que possam se destacar individualmente e atuar de forma sinérgica em equipe.
      </li>

      <li class="list-group-item">
        <strong>Melhoria Contínua:</strong> Acreditamos que sempre podemos evoluir e aprimorar. Por isso, buscamos constantemente o aperfeiçoamento de nossos conhecimentos, processos e práticas organizacionais. Valorizamos o aprendizado com nossos sucessos e desafios, com o objetivo de alcançar resultados cada vez mais excepcionais.
      </li>
    </ul>

    <p class="text-muted mt-3">
      Essa política é amplamente divulgada e compreendida por todos os colaboradores, sendo o alicerce de nossa cultura organizacional. A alta direção da InovahPlast se compromete a disponibilizar os recursos necessários para a efetiva implantação e manutenção do Sistema de Gestão da Qualidade, assegurando a consistência e a eficácia de nossos processos em conformidade com nossos princípios.
    </p>

    <p class="text-muted">
      Através de uma gestão focada na qualidade, aliada à dedicação de nossa equipe, buscamos consolidar a posição da InovahPlast como referência em nosso segmento, proporcionando valor agregado e satisfação a todos os nossos clientes. Estamos empenhados em fortalecer nossa marca como sinônimo de excelência, confiança e responsabilidade, honrando o compromisso com nossos clientes e com a sociedade como um todo.
    </p>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  data: function () {
    return {
      items: [
        require('@/assets/images/factory/fabrica-1.jpeg'),
        require('@/assets/images/factory/fabrica-2.jpeg'),
        require('@/assets/images/factory/fabrica-3.jpeg'),
        require('@/assets/images/factory/fabrica-4.jpeg'),
      ],
      index: null
    };
  },
  metaInfo: {
    title: "Nossa História"
  },
  components: {
    CoolLightBox,
  },
};
</script>

<style scoped>
 .zoom {
   transition: transform .3s;
 }
 .zoom:hover {
   transform: scale(.97);
 }
</style>